import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/apps/blog/src/components/PageLayout/PageLayout.tsx";
import { Container, PageOffset, SkillBlock, Typography } from 'components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
    <SkillBlock skills="reactjs,typescript,go,terraform,postgressql,aws,googlecloud,docker,html,css,reactquery,gatsbyjs,vercel,jamstack,nodejs,nestjs,serverless,kubernetes,python,nextjs" mdxType="SkillBlock">
        <Typography variant="h2" component="h2" mdxType="Typography">Primary stack</Typography>
        I am intensively investing into the development of my backend skills at the moment, but still I am a front-end first fullstack engineer. That is why my primary tech skills are all pretty much around React ecosystem.
        For the backend I use Golang, and previously Node (NestJS). I deploy to AWS/GCP using Terraform and Spinnaker. I also do Serverless sometimes, but more often than not.
    </SkillBlock>
    </Container>
    <br />
    <br />
    <br />
    <Container mdxType="Container">
    <SkillBlock skills="netlify,mobx,expressjs,typeorm,mysql,mongodb,redis,sass,linux,vagrant,apollo" mdxType="SkillBlock">
        <Typography variant="h2" component="h2" mdxType="Typography">Familiar with / rusty in</Typography>
        All listed here I have some professional experience in, but either the duties were basic and occasional, or it happened a long time ago. I can do something relatively simple having a well-tailored reference or a bunch of good examples by my hand, or after some brief picking up.
    </SkillBlock>
    </Container>
    <br />
    <br />
    <br />
    <Container mdxType="Container">
    <SkillBlock skills="meteorjs,bitrix24,php,javascript,less,redux,saga" mdxType="SkillBlock">
        <Typography variant="h2" component="h2" mdxType="Typography">Previous stack</Typography>
        There is a set of technologies I used to work with a lot in quite distant past, but professionally. I can still get back on it on rare occasions if a moment calls for it, but mostly I consider all mentioned here as a something left behind.
    </SkillBlock>
    </Container>
    <br />
    <br />
    <br />
    <Container mdxType="Container">
    <SkillBlock flip skills="blender,unrealengine,arduino,raspberry,cpp,flutter,dart" mdxType="SkillBlock">
        <Typography variant="h2" component="h2" mdxType="Typography">Hobby</Typography>
        Certain technologies I engage purely as a hobbyist, completely independently of my professional activity.
    </SkillBlock>
    </Container>
    <PageOffset mdxType="PageOffset" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      